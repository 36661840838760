import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

// Importation des composants de page
import Users from "./Users";
import Withdraw_demand from "./Withdraw_demand";

function App() {
    return (
        <Router>
            <Routes>
                {" "}
                {/* Remplace Switch par Routes */}
                <Route path="/Users" element={<Users />} />{" "}
                <Route path="/wdemand" element={<Withdraw_demand />} />{" "}
            </Routes>
        </Router>
    );
}

export default App;
