import React, { useEffect, useState } from "react";
import axios from "axios";
import "./standard.css";
import Sidebar from "./components/sidebar";

const Withdraw_demand = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    //const [error, setError] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `http://anonyme.xyz/admin/withdraw/withdrawDemand/0/1`
                );
                console.log(response);

                setData((prevData) => [...prevData, ...response.data.data]); // Ajustez en fonction de la structure de votre réponse
                setTotalPages(response.data.totalPages); // Déterminez si plus de données sont disponibles
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [page]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >=
                    document.body.offsetHeight - 500 &&
                page < totalPages
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [page, totalPages]);

    if (loading) return <p>Chargement...</p>;
    //if (error) return <p>{error}</p>;
    console.log(data);
    return (
        <div className="container-fluid">
            <Sidebar />
            <div className="container-db">
                <div className="title-db">
                    <h4>Utilisateurs</h4>
                    <h6>Liste des utilisateurs enregistrés ({data.length})</h6>
                </div>
                <div className="cont-standard">
                    <table className="table table-striped table-bordered">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">ID</th>
                            <th scope="col">Nom</th>
                            <th scope="col">Prénom</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Compte</th>
                            <th scope="col">Insc date</th>
                        </tr>
                        {data.map((item) => (
                            <tr>
                                <td>
                                    <img
                                        className="user-image"
                                        alt=""
                                        src={
                                            item.u_photo
                                                ? `https://kalaray.mg/api/assets/user/${item.id_users}/images/thumbs/${item.u_photo}`
                                                : "https://kalaray.mg/avatar.png"
                                        }
                                    />
                                </td>
                                <td>{item.id_users}</td>
                                <td>{item.u_lastName}</td>
                                <td>{item.u_firstName}</td>
                                <td>{item.u_phoneNumber}</td>
                                <td>{item.u_account}</td>
                                <td>{item.u_created_at}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Withdraw_demand;
