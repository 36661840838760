import React from "react";

const Sidebar = () => {
    return (
        <div className="sidebar list-group">
            <a
                href="./dashboard.html"
                className="list-group-item list-group-item-action"
                aria-current="true"
            >
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Tableau de bord</h5>
                </div>
                <small>Résumé de l'activité.</small>
            </a>
            <a
                href="./users.html"
                className="list-group-item list-group-item-action active"
                aria-current="true"
            >
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Utilisateurs</h5>
                </div>
                <small>Les utilisateurs enregistrés.</small>
            </a>
            <a
                href="./users.html"
                className="list-group-item list-group-item-action"
                aria-current="true"
            >
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Comptes</h5>
                </div>
                <small>Les utilisateurs enregistrés.</small>
            </a>
            <a
                href="./users.html"
                className="list-group-item list-group-item-action"
                aria-current="true"
            >
                <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">validation</h5>
                </div>
                <small>Validation des paiements.</small>
            </a>
        </div>
    );
};

export default Sidebar;
