import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom"; // Importation de BrowserRouter
import "./index.css";
import App from "./App"; // Assure-toi que ton composant principal s'appelle App
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        {/* Enveloppe ton App avec Router */}
        <App />
    </React.StrictMode>
);

// Si tu veux mesurer les performances de ton application, passe une fonction
// pour enregistrer les résultats (par exemple : reportWebVitals(console.log))
// ou envoie à un endpoint d'analytique. En savoir plus : https://bit.ly/CRA-vitals
reportWebVitals();
